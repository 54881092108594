import Data from './Data'

export const visualGraph = (demo,mediaSelect, mediaValues, mediaPercentage, refChart) =>{

    var label1 = null
    var label2 = null
    var label3 = null
    var label4 = null

    var reach1 = null
    var reach2 = null
    var reach3 = null
    var reach4 = null

    let hide = "hidden"
    let text = ""
    let add_reach = 0

    let media1Style = { visibility: hide,height:"300px"}
    let media2Style = { visibility: hide,height:"350px"}
    let media3Style = { visibility: hide,height:"400px"}
    let media4Style = { visibility: hide}
    let showAdditionalReach = {visibility: hide}

    let mediaPercentage_zero = ""
    let mediaPop_zero = ""

    if (mediaSelect.length > 0){
        label1 = mediaSelect[0].label
        media1Style = { 
            visibility: 'visible',
            height:'400px',
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[0].color})`,
            borderTop: `${mediaSelect[0].color}`,
            bottom: 0,
            zIndex: 999,
        }
        reach1 = mediaSelect[0].reach
        if (Number(mediaPercentage[0]) > 0){
            mediaPercentage_zero = `+${((Number(mediaPercentage[0]))).toFixed(1)}%`;
            mediaPop_zero = `${Math.round((Number(mediaValues[0]*1000))).toLocaleString()}`;
        }
    }
    if (mediaSelect.length > 1){
        label2 = mediaSelect[1].label
        media2Style = { 
                visibility: 'visible',
                height:"400px",
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[1].color})`,
                borderTop: `${mediaSelect[1].color}`,
                bottom: 0,
                zIndex: 888,
        }
        text = "one more"
        add_reach = mediaValues[1] - mediaValues[0]
        showAdditionalReach = { visibility: 'visible', marginTop: "50px"}
        reach2 = mediaSelect[1].reach
    }
    if (mediaSelect.length > 2){
        label3 = mediaSelect[2].label
        media3Style = { visibility: 'visible',
                        height:'400px',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[2].color})`,
                        borderTop: `${mediaSelect[2].color}`,
                        bottom: 0,
                        zIndex: 777,
                    }
        text = "two more"
        add_reach = mediaValues[2] - mediaValues[0]
        reach3 = mediaSelect[2].reach
    }
    if (mediaSelect.length > 3){
        label4 = mediaSelect[3].label
        media4Style = { visibility: 'visible',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[3].color})`,
                        borderTop: `${mediaSelect[3].color}`,
                        height:'400px',
                        bottom: 0,
                        zIndex: 666,
                      }
        text = "three more"
        add_reach = mediaValues[3] - mediaValues[0]
        reach4 = mediaSelect[3].reach
    }

    return(
        <div className="new" ref={refChart} style={{width:"100%",backgroundColor:"white", paddingTop:"10px",}}>
                    <h1 className="visual-header" style={{color:"black"}}>Reach: {demo}</h1>
                    <div className="row graph" >
                        <div className="col" style={{position:'relative'}}>
                            <div className="card boxDiv box-alt mediaone" style={media1Style}>
                                <div className="card-body d-flex flex-column"  >
                                    <div className="card-header-title">
                                        <h5 className="card-title">{label1}</h5>
                                    </div>   
                                    <div className="mt-auto ">
                                        <h1>{mediaPercentage_zero}</h1>
                                        <div className="card-text">Channel Reach %</div>
                                        <p className="card-text textPercentage">{mediaPercentage_zero}</p>
                                        <div className="card-text borderDot">Channel Reach</div>
                                        <p className="card-text textNumber">{mediaPop_zero}</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col"  style={{position:'relative'}}>
                            <div className="card boxDiv box-alt mediatwo" style={media2Style}>
                            <div className="card-body d-flex flex-column"  >
                                <div className="card-header-title">
                                <h5 className="card-title">{label2}</h5>
                                </div>
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[1])-Number(mediaPercentage[0]))).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[1]).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Math.round(Number(mediaValues[1]*1000)).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col"  style={{position:'relative'}}>
                            <div className="card boxDiv box-alt mediathree" style={media3Style}>
                            <div className="card-body d-flex flex-column"  >
                                <div className="card-header-title">
                                <h5 className="card-title">{label3}</h5>
                                </div>
                                
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[2])-Number(mediaPercentage[1]))).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[2]).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Math.round(Number(mediaValues[2]*1000)).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col"  style={{position:'relative'}}>
                            <div className="card boxDiv box-alt mediafour" style={media4Style}>
                            <div className="card-body d-flex flex-column"  >
                                <div className="card-header-title">
                                <h5 className="card-title">{label4}</h5>
                                </div>
                               
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[3])-Number(mediaPercentage[2]))).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[3]).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Math.round(Number(mediaValues[3]*1000)).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="reach-box text-center " style={showAdditionalReach}>
                            New people reached by adding <span style={{fontStyle:"italic"}}>{text}</span> channels: {Math.round(Number(add_reach*1000)).toLocaleString()}
                    </div>
                    <div className="text-center " style={showAdditionalReach}>
                           Source: {Data.graph_source}
                    </div>
                    
                        
        </div>
    )
}