import types from './types';


  function loaderReducer(state = {
    isFetching: false,
    data:{}
  }, action) {
  switch (action.type) {
    case types.DATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        data: null
      })
    case types.DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
        data: action.data,
      })
    case types.DATA_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
        data: null
      })
    case types.DATA_CLEAR:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: 'Data Cleared',
        data: null
      })
    case types.DATA_SEND:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: action.message,
        data: action.data
      })
    
    default:
      return state
  }
}

export default loaderReducer;

