import './style.css';
import AudioReach_v2 from './AudioReach_v2'

function ContainAudioReach_v2() {
  return (
    <>
      <div className="container">
      <div class="row align-items-md-stretch">
      <div class="col-lg-7 col-md-12 bg-light">
        <div class="h-100 p-5 text-bg-dark" >
          <h2>Change the background</h2>
          <p>Swap the background-color utility and add a `.text-*` color utility to mix up the jumbotron look. Then, mix and match with additional component themes and more.</p>
          <button class="btn btn-outline-light" type="button">Example button</button>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 bg-light">
        <div class="h-100 p-5 bg-light">
          <h2 style={{marginTop:"70px"}}>Audio Planning Tool</h2>
          <p style={{marginBottom:"104px"}}>The Audio planning tool is a quick reference application to gauge the cumulative reach in combining various audio formats; broadcast audio, podcasts, and free on-demand music streaming, for key demographics and category purchase intenders. The Audio Planning tool is powered by Nielsen Consumer and Media View (CMV), a market leading single-source dataset, profiling Australian consumers’ demographics, psychographics, category, and media usage.</p>
          <h3>Partnered with:</h3>
          <img  width="200px" src="/img/Nielsen_logo.png"/>
        </div>
      </div>
    </div>
    </div>
      <div className="container py-3" style={{marginTop:"50px", maxWidth:"1600px", }}>
        <div class="card" style={{border:'none'}}>
            <div class="card-body" style={{boxShadow: "0px 10px 10px #00000033", backgroundColor:"#FAFAFA", padding:"50px"}}> 
              <AudioReach_v2 key={3} />
            </div>
          </div>
      </div>

      <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <img src="./img/footer.png" className="img-fluid"/>
      </footer>
    </div>
    </>
  );
}

export default ContainAudioReach_v2;
