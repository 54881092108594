//const apiURL = 'https://api.acras.dra.com.au/'

const mediaURL = 'https://media.acra.dra.com.au/'
const apiURL = 'https://api.tools.commercialradio.au/'

const exportedObject = {
    apiURL,
    mediaURL
  };
  
export default exportedObject
