import axios from 'axios'

import actions from './actions';

import variables from '../variables';

const requestLogin = actions.requestLogin
const receiveLogin = actions.receiveLogin
const loginError = actions.loginError
const requestLogout = actions.requestLogout
const receiveLogout = actions.receiveLogout



export function loginUser(creds) {

    return dispatch => {
        dispatch(requestLogin(creds))
        localStorage.setItem('cratools_email', `${creds.username}`)
         
        dispatch(receiveLogin(`${creds.username}`))
        
    }
}

export function loginSocialUser(token,url) {

    return dispatch => {
        dispatch(requestLogin(token))

        return axios.post(variables.apiURL+url, { access_token: `${token}`})
        .then(function(response){
            localStorage.setItem('cratools_token', response.data.key)
         
            dispatch(receiveLogin(response.data.token))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                dispatch(loginError(error.response.data.non_field_errors[0]))
            }else{
              dispatch(loginError('Error Logging into server'))  
            }
        });
    }
}

export function logoutUser() {
    return dispatch => {
      dispatch(requestLogout())
      localStorage.removeItem('cratools_token')
      return axios.post(variables.apiURL+'rest-auth/logout/')
      .then(function(response){
        dispatch(receiveLogout())
      })
      .catch(function(error){
        dispatch(receiveLogout())
      });

    }
  }


const exportedObject = {
  loginUser,
  logoutUser,
  loginSocialUser
};

export default exportedObject

