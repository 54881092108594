import React from 'react';



import ContainAudioReach from './ContainAudioReach'
import ContainAudioReach_v2 from './ContainAudioReach_v2'

import { Col, Modal,Container, Row,Spinner } from 'react-bootstrap';
import { Navigate,Routes, Route,NavLink,Redirect, matchPath, useNavigate } from 'react-router-dom'
import ErrorShow from './ErrorShow'

//Page imports
import Template from './Template'
import { connect } from 'react-redux'
import axios from 'axios'

import variables from '../redux/variables';
import { ContinuousColorLegend } from 'react-vis';

const Whoops404 = () => (
	<div className="">
		Page does not exist!
		
    </div>
);

const contentStyle ={
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '6px',
    paddingTop: '20px'
}

class UserMain extends React.Component{
    constructor(props){
        super(props)
        this.state = {


        }

        
    
    }

    componentDidMount() {
        /*document.body.style.background = "url(/dist/img/body-bg.jpg)";*/
        console.log(this.props )
        const { isAuthenticated } = this.props

        if (isAuthenticated){
            var email = ""
            try{
                email = localStorage.getItem('cratools_email')
            }catch(err){
                email = sessionStorage.getItem('cratools_email')
            }
            var data = {email: email,site:"audio_planning_tool"}
            axios.post(variables.apiURL+'api/visit/', data, {headers: {'Authorization':`Token bb4867f44477c4ea0ff25dc32b4d3946923e8138`}})
            .then(function(response){
  
            })
            .catch(function(error){
                
            });
        }
            
  
  
    }
    
    componentWillUnmount(){
    }

    render(){
        const { isAuthenticated, isError } = this.props
        console.log(isError)
        return(
            <main>
                <style type="text/css">
                            {`
                           .modal-60w {
                                width: 60px;
                                max-width: none!important;
                                padding: 0;
                            }

                            select {
                                text-align: center;
                                text-align-last: center;
                                font-size: 10px;
                              }
                              option {
                                text-align: center;
                              }

                           



                            `}
                </style>
                
                {isAuthenticated ?  null: <Navigate to='/signin' replace={true}/>}
                <Container fluid>
                    <Row>
                        <Col style={contentStyle}>
                        {
                            <Routes>
                                 <Route exact path ='/'  element={<ContainAudioReach key={3}/>}/> 
                            </Routes>
                        }
                        </Col>
                    </Row>
                </Container>
                
            </main>
        )
    }

}

/*
<Route exact path ='/portal/judge/:panelid/:categoryid' 
    key={2} component={(props)=><UserEntries {...props} 
    panelid={this.props.match.params.panelid} 
    categoryid={this.props.match.params.categoryid}
    />}
    
    />

*/

function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage, isError } = auth
  
    return {
      isAuthenticated,
      isError,
      errorMessage
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    if (props.isError) {
        return <ErrorShow {...props} navigate={navigate} />

    }else{
        return <UserMain {...props} navigate={navigate} />
    }

}

export default connect(mapStateToProps)(WithNavigate)


//export default connect(mapStateToProps)(UserMain)



