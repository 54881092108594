//types.js

const DATA_REQUEST = 'DATA_REQUEST'
const DATA_SUCCESS = 'DATA_SUCCESS'
const DATA_FAILURE = 'DATA_FAILURE'
const DATA_CLEAR = 'DATA_CLEAR'
const DATA_SEND = 'DATA_SEND'

const exportedObject = {
    DATA_REQUEST,
    DATA_SUCCESS,
    DATA_FAILURE,
    DATA_CLEAR,
    DATA_SEND,

  };
  
export default exportedObject
