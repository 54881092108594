import types from './types';

function catchError(){
  try{
    var result = localStorage.getItem('cratools_email') ? true : false
    return false
  }catch(err){
    return true
  }

}
  
  function getAuthenicated(){
    var result = false
    try{
      result = localStorage.getItem('cratools_email') ? true : false
    }catch(err){
      result = null
    }
    return result
  }

  function authReducer(state = {
    isFetching: false,
    isError: catchError(), //catchError()
    isAuthenticated: getAuthenicated()
  }, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds
      })
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        token: action.token,
      })
    case types.LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      })
    default:
      return state
  }
}

export default authReducer;

