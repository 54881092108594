import React, { Component } from "react";


import Select, {ActionMeta, NonceProvider, StylesConfig } from 'react-select';
import { PieChart } from 'react-minimal-pie-chart';
import './style.css'

import chroma from 'chroma-js'
import {visualGraph} from './visualGraph';
import {visualGraph_v2} from './visualGraph_v2'
import {visualGraph_v3} from './visualGraph_v3'
import * as htmlToImage from 'html-to-image';

import { connect } from 'react-redux'
import axios from 'axios'

import variables from '../redux/variables';


const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#E81F76' : 'white',
      color: state.isSelected ? 'white' : 'inherit',

    }),
    control: (style) => ({
      // none of react-select's styles are passed to <Control />
      ...style,
      border:'none',

    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', border:'none', }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
  };

const selectItems = (rows, ref ,onChange) =>{
    return (<select ref={ref} onChange={()=>onChange()} className="form-select" aria-label="Default select example">
        {
            rows.map((row,k) =>
                <option value={`${k}`}>{row}</option>
            )
        }
    </select>)
}


const visualsGraph = (demo,mediaSelect, mediaValues, mediaPercentage) =>{

    var label1 = null
    var label2 = null
    var label3 = null
    var label4 = null

    var reach1 = null
    var reach2 = null
    var reach3 = null
    var reach4 = null

    let hide = "hidden"
    let text = ""
    let add_reach = 0

    let media1Style = { visibility: hide,height:"300px"}
    let media2Style = { visibility: hide,height:"350px"}
    let media3Style = { visibility: hide,height:"400px"}
    let media4Style = { visibility: hide}
    let showAdditionalReach = {visibility: hide}


    if (mediaSelect.length > 0){
        label1 = mediaSelect[0].label
        media1Style = { 
            visibility: 'visible',
            height:"300px",
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[0].color})`
        }
        reach1 = mediaSelect[0].reach
        
    }
    if (mediaSelect.length > 1){
        label2 = mediaSelect[1].label
        media2Style = { 
                visibility: 'visible',
                height:"350px",
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[1].color})`
        }
        text = "one more"
        add_reach = mediaValues[1] - mediaValues[0]
        showAdditionalReach = { visibility: 'visible'}
        reach2 = mediaSelect[1].reach
    }
    if (mediaSelect.length > 2){
        label3 = mediaSelect[2].label
        media3Style = { visibility: 'visible',
                        height:"400px",
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[2].color})`
                    }
        text = "two more"
        add_reach = mediaValues[2] - mediaValues[0]
        reach3 = mediaSelect[2].reach
    }
    if (mediaSelect.length > 3){
        label4 = mediaSelect[3].label
        media4Style = { visibility: 'visible',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[3].color})`
                      }
        text = "three more"
        add_reach = mediaValues[3] - mediaValues[0]
        reach4 = mediaSelect[3].reach
    }

    return(
        <div style={{ width:"100%",backgroundColor:"black", paddingTop:"10px", height: "600px" }}>
                    <h1 className="visual-header" style={{color:"white"}}>{demo}</h1>
                    <div className="reach-box text-center " style={showAdditionalReach}>
                            New people reached by adding <span style={{fontStyle:"italic"}}>{text}</span> channels: {Number(add_reach*1000).toLocaleString()}
                    </div>
                    <div className="imageDiv" style={media4Style}>
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label4}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[3]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[3]*1000).toLocaleString()}</p>
                                

                            </div>
                        </div>
                    </div>

                    {
                    <div style={media3Style} className="imageDiv bottomDiv"  >
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label3}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[2]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[2]*1000).toLocaleString()}</p>
                          
                            </div>
                        </div>
                    </div>
                    }

                    {
                    <div  style={media2Style} className="imageDiv bottomDivTwo">
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label2}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[1]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[1]*1000).toLocaleString()}</p>
                                
                            </div>
                        </div>
                    </div>
                    }
                    {
                        <div  style={media1Style} className="imageDiv bottomDivThree">
                            <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                                <h5 className="card-title">{label1}</h5>
                                <div className="mt-auto ">
                                    <div className="card-text">Channel Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[0]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Channel Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[0]*1000).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                        </div>
                    }  
                        
        </div>
    )
}

/*
const dataSurvey = {
    'Totals':['(000s)',21280,10523,2993,2387,5697,4826,7137],
    '1':['(000s)',13856,7152,1760,1385,3746,3406,4527],
    '2':['(000s)',5842,3686,1120,910,2173,1512,845],
    '3':['(000s)',5306,3138,829,659,1681,1457,1162],
    '4':['(000s)',3561,2229,546,433,1279,950,685],
    '1,2':['(000s)',15534,8132,2147,1701,4365,3767,4789],
    '1,3':['(000s)',15636,8166,2077,1637,4336,3830,4913],
    '1,4':['(000s)',14953,7803,1965,1548,4144,3659,4738],
    '2,3':['(000s)',8798,5384,1572,1269,3086,2298,1547],
    '2,4':['(000s)',7586,4761,1345,1092,2797,1964,1248],
    '3,4':['(000s)',6067,3583,873,687,1924,1659,1419],
    '1,2,3':['(000s)',16609,8733,2333,1850,4721,4011,5030],
    '1,2,4':['(000s)',16125,8486,2244,1779,4581,3905,4913],
    '1,3,4':['(000s)',15859,8287,2095,1647,4400,3887,4993],
    '2,3,4':['(000s)',9384,5732,1596,1283,3279,2453,1748],
    '1,2,3,4':['(000s)',16787,8835,2342,1854,4778,4058,5093],
}*/

const metroData = {
    'Totals':['(000s)',15087,7779,2182,1756,4280,3499,4693,8280,5738,7404,7683,10386,2357,2798,1194,3315,5965,13802,10605,11522,7624,12650,1086,1717],
    '1':['(000s)',9859,5212,1230,980,2750,2463,3141,5592,4099,4936,4923,6999,1698,1951,853,2318,4120,9127,7102,7635,5173,8368,766,1181],
    '2':['(000s)',4129,2609,746,614,1499,1110,640,2232,1729,2367,1763,2742,948,1112,462,1735,1906,3724,3038,3371,1965,3444,456,424],
    '3':['(000s)',3919,2302,647,524,1198,1105,838,2075,1651,1902,2017,2630,695,792,301,991,1622,3569,2814,3098,1886,3261,294,440],
    '4':['(000s)',2682,1659,421,349,895,764,519,1440,1192,1323,1359,1797,493,580,228,833,1156,2434,1936,2142,1284,2227,221,312],
    '1,2':['(000s)',11088,5935,1515,1215,3211,2724,3324,6166,4492,5581,5507,7770,1914,2226,958,2776,4598,10216,7977,8606,5720,9370,876,1297],
    '1,3':['(000s)',11189,5973,1497,1195,3201,2772,3392,6230,4562,5556,5633,7810,1915,2224,931,2638,4601,10318,8038,8668,5766,9447,858,1308],
    '1,4':['(000s)',10718,5723,1400,1119,3058,2665,3294,5984,4422,5345,5373,7525,1841,2135,906,2558,4431,9901,7707,8317,5567,9075,831,1267],
    '2,3':['(000s)',6295,3844,1118,906,2146,1698,1120,3360,2604,3394,2900,4195,1305,1505,606,2131,2746,5706,4581,5077,3004,5243,592,675],
    '2,4':['(000s)',5456,3409,934,764,1941,1468,942,2961,2294,2980,2475,3649,1153,1366,558,2003,2436,4942,3986,4422,2625,4551,551,582],
    '3,4':['(000s)',4493,2624,679,542,1352,1272,1038,2402,1899,2169,2324,3039,771,893,349,1119,1845,4097,3202,3535,2184,3744,327,524],
    '1,2,3':['(000s)',11885,6377,1682,1348,3474,2903,3471,6551,4751,5947,5938,8252,2038,2378,999,2924,4876,10931,8528,9225,6068,10007,924,1376],
    '1,2,4':['(000s)',11554,6209,1601,1284,3375,2834,3414,6391,4655,5793,5761,8058,1985,2323,982,2864,4760,10640,8298,8979,5946,9758,911,1342],
    '1,3,4':['(000s)',11363,6069,1510,1201,3249,2821,3453,6321,4641,5631,5731,7931,1933,2253,945,2672,4666,10479,8162,8802,5856,9597,866,1333],
    '2,3,4':['(000s)',6745,4099,1139,917,2273,1826,1279,3621,2795,3590,3155,4522,1355,1579,641,2211,2918,6121,4890,5423,3235,5620,621,738],
    '1,2,3,4':['(000s)',12018,6452,1689,1350,3512,2940,3519,6623,4811,6000,6018,8348,2050,2397,1008,2945,4928,11056,8624,9331,6137,10123,931,1394],
}

const regionalData = {
    'Totals': ['(000s)',6196,2793,802,620,1390,1403,2409,3488,2156,3061,3135,4738,984,1189,424,1250,2421,5823,4468,4395,3216,5164,392,701],
    '1': ['(000s)',3954,1859,536,402,894,966,1414,2201,1462,2092,1862,3038,750,909,300,925,1670,3713,2899,2898,2060,3333,301,454],
    '2': ['(000s)',1531,828,383,295,469,359,236,785,557,986,545,1092,444,532,140,709,743,1413,1183,1238,672,1302,170,133],
    '3': ['(000s)',1369,779,192,141,432,346,353,747,530,702,667,1020,226,288,110,324,554,1266,986,1041,694,1141,76,150],
    '4': ['(000s)',821,490,131,98,306,184,176,447,326,470,351,587,160,203,68,266,368,749,602,654,401,692,49,84],
    '1,2': ['(000s)',4379,2084,641,491,1024,1060,1495,2405,1610,2325,2054,3323,815,989,329,1064,1827,4103,3219,3227,2249,3694,326,494],
    '1,3': ['(000s)',4422,2115,593,446,1049,1066,1553,2459,1622,2317,2105,3372,800,971,338,1008,1837,4153,3217,3239,2287,3711,315,507],
    '1,4': ['(000s)',4222,2017,576,436,997,1020,1473,2343,1551,2228,1994,3213,781,949,326,992,1789,3964,3091,3107,2187,3557,309,484],
    '2,3': ['(000s)',2366,1302,474,364,735,567,485,1260,891,1361,1005,1730,561,676,203,841,1076,2191,1766,1862,1119,1995,209,235],
    '2,4': ['(000s)',1934,1088,415,322,625,463,342,1024,723,1186,748,1387,500,601,172,788,922,1785,1472,1549,895,1642,184,182],
    '3,4': ['(000s)',1559,876,204,153,482,394,431,855,607,802,757,1156,249,314,119,361,637,1445,1114,1179,805,1307,87,172],
    '1,2,3': ['(000s)',4692,2260,668,511,1138,1122,1599,2585,1724,2463,2230,3563,852,1031,356,1108,1935,4402,3428,3451,2411,3945,338,526],
    '1,2,4': ['(000s)',4531,2186,650,500,1089,1096,1535,2495,1664,2393,2138,3433,834,1009,347,1092,1896,4250,3330,3343,2331,3821,331,511],
    '1,3,4': ['(000s)',4493,2148,602,455,1064,1084,1581,2499,1642,2349,2144,3422,806,976,341,1023,1868,4221,3266,3290,2326,3775,318,514],
    '2,3,4': ['(000s)',2513,1387,477,367,777,610,541,1342,950,1433,1081,1833,577,695,210,869,1143,2332,1872,1966,1207,2125,216,255],
    '1,2,3,4': ['(000s)',4749,2291,669,513,1152,1138,1622,2619,1743,2487,2262,3603,858,1036,359,1119,1961,4456,3470,3490,2443,3995,340,533],
}


const nationalData = {
    'Totals': ['(000s)',21283,10572,2984,2376,5670,4902,7102,11768,7894,10465,10818,15124,3340,3987,1618,4564,8386,19626,15073,15917,10841,17815,1478,2418],
    '1': ['(000s)',13813,7072,1766,1382,3643,3428,4555,7793,5562,7029,6785,10037,2449,2861,1153,3243,5790,12839,10000,10533,7233,11701,1067,1635],
    '2': ['(000s)',5660,3437,1129,909,1968,1468,877,3017,2287,3353,2307,3834,1392,1644,602,2444,2649,5137,4220,4609,2637,4745,625,557],
    '3': ['(000s)',5288,3081,839,666,1630,1451,1191,2822,2181,2604,2683,3650,921,1080,411,1315,2176,4835,3801,4139,2581,4402,370,590],
    '4': ['(000s)',3503,2149,552,446,1201,948,695,1887,1518,1794,1709,2384,653,783,296,1099,1524,3183,2538,2796,1685,2919,270,396],
    '1,2': ['(000s)',15467,8019,2156,1706,4235,3783,4819,8571,6102,7906,7561,11093,2729,3214,1287,3840,6424,14319,11196,11832,7969,13064,1203,1791],
    '1,3': ['(000s)',15611,8088,2090,1641,4250,3838,4945,8690,6184,7873,7738,11182,2715,3195,1270,3647,6437,14471,11255,11907,8052,13158,1173,1814],
    '1,4': ['(000s)',14940,7740,1976,1555,4056,3684,4767,8327,5972,7573,7367,10739,2622,3084,1231,3550,6219,13864,10798,11425,7755,12632,1140,1751],
    '2,3': ['(000s)',8661,5146,1592,1270,2881,2265,1605,4620,3495,4756,3905,5926,1866,2180,808,2973,3822,7897,6347,6939,4124,7238,801,910],
    '2,4': ['(000s)',7390,4497,1349,1087,2567,1931,1284,3985,3017,4167,3223,5036,1653,1968,730,2792,3358,6727,5458,5970,3520,6193,735,764],
    '3,4': ['(000s)',6052,3500,884,695,1835,1665,1469,3257,2507,2972,3081,4195,1021,1208,468,1480,2482,5542,4316,4715,2990,5051,414,695],
    '1,2,3': ['(000s)',16577,8637,2350,1859,4613,4025,5069,9137,6475,8409,8167,11815,2890,3409,1355,4031,6811,15333,11957,12676,8479,13952,1262,1902],
    '1,2,4': ['(000s)',16085,8395,2250,1783,4465,3930,4949,8887,6319,8186,7899,11491,2819,3332,1328,3956,6656,14890,11627,12322,8278,13579,1242,1854],
    '1,3,4': ['(000s)',15855,8217,2112,1656,4313,3904,5034,8820,6283,7980,7875,11353,2739,3229,1286,3695,6534,14700,11428,12093,8182,13372,1184,1847],
    '2,3,4': ['(000s)',9258,5486,1617,1283,3050,2436,1820,4963,3745,5023,4235,6355,1932,2274,851,3080,4060,8453,6761,7388,4442,7745,836,993],
    '1,2,3,4': ['(000s)',16767,8743,2359,1863,4664,4079,5141,9242,6554,8487,8280,11951,2908,3433,1367,4065,6889,15512,12094,12821,8580,14118,1271,1927],
}

const orderOptions = (values) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };

class AudioReach_v2 extends Component{
    constructor(props){
        super(props);
        this.state = {
            media: [0,0,0,0],
            demo: 0,
            mediaValues: [0,0,0,0],
            mediaPercentage: [0,0,0,0],
            mediaReach:[0,0,0,0],
            mediaSelect: [ { value: 1, label: 'Commercial Radio', reach: 0, color:'#00aeef', isFixed:true }],
            marketSelect:[],
           
            demoSelect: [],
            
            marketData:metroData,
            donut_chart_data: [],
            crafixed: true,
            selectcount: 0,
            media_select: [
                { value: 1, label: 'Commercial Radio', reach: 0, color:'#00aeef', isFixed:true },
                { value: 2, label: 'Digital Radio Streaming', reach: 0 , color:'#e81f76', isFixed:false},
                { value: 3, label: 'Free On-Demand Music Streaming', reach: 0 , color:'#07AD53', isFixed:false},
                { value: 4, label: 'Podcasts', reach: 0 , color:'#9164cc', isFixed:false},
            ],

        }

        this.refChart = React.createRef();
        this.refDemo = React.createRef();
        this.refMedia1 = React.createRef();
        this.refMedia2 = React.createRef();
        this.refMedia3 = React.createRef();
        this.refMedia4 = React.createRef();

        this.refMediaSelect = React.createRef();

        this.onChangeMedia = this.onChangeMedia.bind(this);
        this.onChangeDemo = this.onChangeDemo.bind(this);
        this.onChangeDemoSelect = this.onChangeDemoSelect.bind(this);
        this.onChangeMediaSelect = this.onChangeMediaSelect.bind(this);
        this.updateFiguresMulti = this.updateFiguresMulti.bind(this);
        this.handleDownloadImage = this.handleDownloadImage.bind(this)

        this.onChangeMarketSelect = this.onChangeMarketSelect.bind(this);
    }

    onChangeMediaSelect(value, actionMeta){
        //const refMediaSelect = this.refMediaSelect.current;
        /*option.sort(function(a, b) {
            return a.reach - b.reach;
          });*/
          var count = this.state.selectcount + 1
          this.setState({selectcount:count})
          if (count == 3){
            
            value = value.map(({ value,label, color, reach }) => ({ label: label, value: value,color: color, reach: reach, isFixed:false }));
            var media_select = this.state.media_select.map(({ value,label, color, reach }) => ({ label: label, value: value,color: color, reach: reach, isFixed:false }));
            this.setState({crafixed:false, media_select:media_select})
        }
          switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
              if (actionMeta.removedValue.isFixed) {
                return;
              }
              break;
            case 'clear':
              value = this.state.media_select.filter((v) => v.isFixed);
              break;
          }
      
        value = orderOptions(value);
        this.setState({mediaSelect: value },this.updateFiguresMulti)
        
    }

    mediaReturnKey(select_array){
        var values = []
        select_array.forEach(function (arrayItem) {
            values.push(arrayItem.value)
        })

        return values.sort().toString()
    }



    updateDonutData(mediaArray){
        this.setState({donut_chart_data:[]})
        const donut_data = mediaArray.map(({ label, reach, color }) => ({ label: label, value: reach,color: color }));
        
        this.setState({donut_chart_data:donut_data})

    }

    updateFiguresMulti(){
        const dataSurvey = this.state.marketData
        const mediaArray = this.state.mediaSelect
        this.updateDonutData(mediaArray)
        if (mediaArray.length > 0){
            const keyMedia1 = mediaArray[0].value.toString();
            const keyMedia2 = this.mediaReturnKey(mediaArray.slice(0, 2))
            const keyMedia3 = this.mediaReturnKey(mediaArray.slice(0, 3))
            const keyMedia4 = this.mediaReturnKey(mediaArray)
        
            //console.log(keyMedia1)
            //console.log(keyMedia2)
            //console.log(keyMedia3)
            //console.log(keyMedia4)
            var percentMedia1 = 0
            var percentMedia2 = 0
            var percentMedia3 = 0
            var percentMedia4 = 0

            //try {
                var demograph = parseInt(this.state.demo)
                this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],0,0,0]})
                console.log(demograph)
                console.log(dataSurvey[keyMedia1][demograph])
                percentMedia1 =  dataSurvey[keyMedia1][demograph]/dataSurvey["Totals"][demograph]
                //console.log(demograph)
                if (keyMedia2.length == 3){
                    console.log(dataSurvey[keyMedia2][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],0,0]})
                    percentMedia2 =  dataSurvey[keyMedia2][demograph]/dataSurvey["Totals"][demograph]
                }
                if (keyMedia3.length == 5){
                    console.log(dataSurvey[keyMedia3][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],dataSurvey[keyMedia3][demograph],0]})
                    percentMedia3 =  dataSurvey[keyMedia3][demograph]/dataSurvey["Totals"][demograph]
                }
                if (keyMedia4.length == 7){
                    console.log(dataSurvey[keyMedia4][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],dataSurvey[keyMedia3][demograph],dataSurvey[keyMedia4][demograph]]})
                    percentMedia4 =  dataSurvey[keyMedia4][demograph]/dataSurvey["Totals"][demograph]
                }

                this.setState({mediaPercentage:[percentMedia1,percentMedia2,percentMedia3,percentMedia4]})
            //} catch(e) {}
        }else{
            this.setState({mediaValues:[0,0,0,0]})
        }
    }

    onChangeMedia(){
        const refMedia1 = this.refMedia1.current;
        const refMedia2 = this.refMedia2.current;
        const refMedia3 = this.refMedia3.current;
        const refMedia4 = this.refMedia4.current;

        this.setState({demo:this.refDemo.current.value,media:[refMedia1.value,refMedia2.value,refMedia3.value,refMedia4.value]},
            this.updateFigures)

        
    }


    updateFigures(){
        const dataSurvey = this.state.marketData
        const mediaArray = this.state.media
        const keyMedia1 = mediaArray[0].toString();
        const keyMedia2 = mediaArray.slice(0, 2).sort().toString();
        const keyMedia3 = mediaArray.slice(0, 3).sort().toString();
        const keyMedia4 = mediaArray.sort().toString();

        console.log(keyMedia1.toString())
        console.log(keyMedia2.toString())
        console.log(keyMedia3.toString())
        console.log(keyMedia4.toString())
        try {
            console.log(dataSurvey[keyMedia1][this.state.demo])
            this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],0,0,0]})
            if (!keyMedia2.includes('0')){
                console.log(dataSurvey[keyMedia2][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],0,0]})
            }
            if (!keyMedia3.includes('0')){
                console.log(dataSurvey[keyMedia3][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],dataSurvey[keyMedia3][this.state.demo],0]})
            }
            if (!keyMedia4.includes('0')){
                console.log(dataSurvey[keyMedia4][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],dataSurvey[keyMedia3][this.state.demo],dataSurvey[keyMedia4][this.state.demo]]})
            }
        } catch(e) {}

    }

    onChangeDemo(){
        
        this.setState({demo:this.refDemo.current.value, },this.updateFiguresMulti())
        
        
    }

    onChangeMarketSelect(option){
        console.log(option)
        this.setState({
            marketSelect:option,marketData:option.value, mediaSelect:this.updateDataReach(this.state.mediaSelect,this.state.demoSelect.value,option.value)
        },this.updateFiguresMulti)
        
    }

    updateDataReach(mediaArray,index, marketData){
        const mediaReturn = mediaArray.map(({ value,label, color, isFixed }) => ({ label: label, value: value,color: color, reach: marketData[value][index], isFixed:isFixed }));

            //const refMediaSelect = this.refMediaSelect.current;
        /*mediaReturn.sort(function(a, b) {
            return a.reach - b.reach;
        });*/

        return mediaReturn
    }

    

    onChangeDemoSelect(option){
        const dataSurvey = this.state.marketData
        const media_select = [
            { value: 1, label: 'Commercial Radio', reach: dataSurvey['1'][option.value], color:'#00aeef', isFixed:this.state.crafixed },
            { value: 2, label: 'Digital Radio Streaming', reach: dataSurvey['2'][option.value] , color:'#e81f76', isFixed:false},
            { value: 3, label: 'Free On-Demand Music Streaming', reach: dataSurvey['3'][option.value] , color:'#07AD53', isFixed:false},
            { value: 4, label: 'Podcasts', reach: dataSurvey['4'][option.value] , color:'#9164cc', isFixed:false},
        ]
        console.log(option)
        this.setState({demo: option.value,
            demoSelect:option, media_select:media_select, mediaSelect:this.updateDataReach(this.state.mediaSelect,option.value, this.state.marketData)
        },this.updateFiguresMulti)
        
    }

    async handleDownloadImage(){
        
        const element = this.refChart.current;
        const dataUrl = await htmlToImage.toPng(element);

        const link = document.createElement('a');
        link.download = "Audio-Reach-Chart.png";
        link.href = dataUrl;
        link.click();

    }

    //07AD53


    componentDidMount(){


        
    }



    render(){
        const market = [
            { value: metroData, label: 'Metro' },
            { value: regionalData, label: 'Regional' },
            { value: nationalData, label: 'National' },

        ]
        const org_media = [
        "None",
        "Commercial Radio",
        "Online Radio Streaming",
        "Free On-Demand Music Streaming",
        "Podcasts",]


        const demo =[
            { value: 1, label: 'People 14+' },
            { value: 2, label: 'People 25-54' },
            { value: 3, label: 'People 16-24' },
            { value: 4, label: 'People 18-24' },
            { value: 5, label: 'People 25-39' },
            { value: 6, label: 'People 40-54' },
            { value: 7, label: 'People 55+' },
            { value: 8, label: 'Main GB' },
            { value: 9, label: 'Parents with Children in home' },
            { value: 10, label: 'Male' },
            { value: 11, label: 'Female' },
            { value: 12, label: 'HAVE MOTOR INSURANCE' },
            { value: 13, label: 'HEALTH INSURANCE: SWITCH OR NEW CUSTOMER N12M' },
            { value: 14, label: 'BANKING: Change MFI N6M' },
            { value: 15, label: 'NEW/USED CAR BUYERS N6M' },
            { value: 16, label: 'CHANGE MOBILE PHONE PROVIDER, CONSIDER N12M' },
            { value: 17, label: 'MAJOR HH APPLIANCE/FURNISHING BUY N12M' },
            { value: 18, label: 'OTHER HH APPLIANCE/FURNISHING BUY N12M' },
            { value: 19, label: 'DEPARTMENT STORE: PURCHASE FROM PMTH' },
            { value: 20, label: 'QSR AT LEAST MONTHLY (eat in, or takeaway, or home delivery)' },
            { value: 21, label: 'ALCOHOL PAST MONTH' },
            { value: 22, label: 'SUPERMARKET at least weekly' },
            { value: 23, label: 'BUILDING/STRUCTURE INSURANCE N12M (extremely likely/very likely)' },
            { value: 24, label: 'EXISTING BUILDING/STRUCTURE INSURANCE shop around for another policy n12m' },

        ]

        const defaultLabelStyle = {
            fontSize: '5px',
            fontFamily: 'sans-serif',
          };

          /*
            <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <PieChart style={{maxHeight: '500px'}}
                            label={({ dataEntry }) => dataEntry.label}
                            labelStyle={{
                                fontSize: '3px',
                                fontFamily: 'sans-serif',
                              }}
                            lineWidth={60} 
                            paddingAngle={5}
                            data={this.state.donut_chart_data}
                        />
            </div>

          */
        

        return(
            <>
                <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        </div>
                        <h3 className="select-box-label">Step 1 - Select Market</h3>
                        <Select
                                    options={market}
                                    value={this.state.marketSelect}
                                    onChange={(option)=>this.onChangeMarketSelect(option)}
                                    className="select-control"
                                    styles={customStyles}
                                />
                    </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        
                        </div>
                        <h3 className="select-box-label">Step 2 - Select age demo</h3>
                        <Select
                                    options={demo}
                                    value={this.state.demoSelect}
                                    onChange={(option)=>this.onChangeDemoSelect(option)}
                                    className="select-control"
                                    styles={customStyles}
                                />
                    </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        
                        </div>
                        <h3 className="select-box-label">Step 3 - Select Medias</h3>
                        <Select
                                    isMulti
                                    name="media_select"
                                    options={this.state.media_select}
                                    onChange={(option, actionMeta)=>this.onChangeMediaSelect(option,actionMeta)}
                                    value={this.state.mediaSelect}
                                    isClearable={this.state.mediaSelect.some((v) => !v.isFixed)}
                                    styles={colourStyles}
                                    className="select-control"
                                />
                    </div>
                </div>
                
                <div class="row g-4 py-5 row-cols-md-1 row-cols-lg-12">
                {visualGraph_v3(this.state.demoSelect.label,
                                this.state.mediaSelect,
                                this.state.mediaValues, 
                                this.state.mediaPercentage,this.refChart)}

                </div>

                <button type="button" onClick={this.handleDownloadImage} className="btn btn-primary btn-lg btn-block">Download as Image</button>


                
                <div class="row g-4 py-5 row-cols-1 row-cols-lg-12 bg-light">
                    <p style={{fontSize:'8px'}}>

                        CMV BUILD DETAILS:
                        <ul>
                        <li>Radio: Media Summary- Any Commercial Radio P7D</li>
                        <li>Commercial on-demand music streaming: Online Music Streaming Services P7D (Includes Google Play Music; or iTunes; or SoundCloud; or Tidal; or Spotify; or Vevo; or Apple Music; or Amazon Music; or YouTube Music; or Other) with an AND [Subscribe to Free Music OR Neither Subscribe or Pay for Music]</li>
                        <li>Digital radio streaming: Live online radio services listened P7D (iHeart Radio; or RadioApp; or TuneIn; or Apple Music; or iTunes; or Other)</li>
                        <li>Commercial Podcast: Used the following services to listen to a Podcast, P7D (Google Podcast; or Apple Podcast; or iTunes; or Spotify; or LiSTNR; or Acast; or SoundCloud; or iHeart Radio; or Other ), OR Listen to catch-up radio Podcast (Hit; or MMM; or Nova; or Smooth; or KIIS; or Gold; or Nine Radio; or Other)with an AND [Subscribe to Free Music OR Neither Subscribe or Pay for Music]</li>
                        </ul>

                    </p>
                    
                </div>

                
               
                

                
            </>
        )
    }
}


function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}



export default connect(mapStateToProps)(AudioReach_v2)
