import React from 'react';
import logo from './logo.svg';

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import UserMain from './components/UserMain'
import UserEmail from './components/UserEmail'
import ErrorShow from './components/ErrorShow'
import './App.css';

import { connect } from 'react-redux'


const Whoops404 = () => (
	<div className="">
		Page does not exist!
		
    </div>
);

class App extends React.Component {


  render(){
    return (
        <BrowserRouter>
          <Routes>
            <Route path ='/' element={<UserMain key={1}/>}/> 
            <Route path ='/signin' element={<UserEmail  key={3}/>}/> 
            <Route path='*' element={<Whoops404/>}/>
          </Routes>
        </BrowserRouter>
    )
  }

}



export default App


