import React from 'react';
import { Alert, Image,Container, Row, Button,Card,Spinner,Form, NavItem } from 'react-bootstrap';


import { connect } from 'react-redux'

import { authOperations } from '../redux/auth/'
import PropTypes from 'prop-types';

import { Navigate,useNavigate, Link } from 'react-router-dom';

import axios from 'axios'

import variables from '../redux/variables';


const backgroundStyle = {
    backgroundColor: 'black',
  };

const fontColor = {
    color: 'white',

}

const logoSpacing= {
    marginBottom: '20px',
}
  

class ErrorShow extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            setPage: 'signIn'
        }
        


    }

    componentDidMount() {
        
        
    }


    


    render(){

        return(
            
            <div className="d-flex align-items-center" style={{topMargin:'100px',display:'flex',justifyContent:'center'}}>
                <p>
                    The Audio Planning tool requires third party cookies to be enabled.
                    Please check your browser settings.
                </p>
            </div>
        )
    }

}



function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}

export default connect(mapStateToProps)(ErrorShow)