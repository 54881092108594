import { combineReducers } from 'redux';

import  authReducer  from './auth/'
import  loaderReducer  from './loader/'

const rootReducer = combineReducers({
    auth: authReducer,
    loader: loaderReducer
  });
  
  export default rootReducer;