import './style.css';
import AudioReach from './AudioReach'

function ContainAudioReach() {
  return (
    <>

      <div className="container container-first py-3">
          <div style={{}}>
            <AudioReach key={3} />
          </div>
      </div>

      
    </>
  );
}

export default ContainAudioReach;
