import React from 'react';
import { Alert, Image,Container, Row, Button,Card,Spinner,Form, NavItem } from 'react-bootstrap';


import { connect } from 'react-redux'

import { authOperations } from '../redux/auth/'
import PropTypes from 'prop-types';

import { Navigate,useNavigate, Link } from 'react-router-dom';

import axios from 'axios'

import variables from '../redux/variables';
    


const backgroundStyle = {
    backgroundColor: 'black',
  };

const fontColor = {
    color: 'black',

}

const logoSpacing= {
    marginBottom: '20px',
}
  



class UserEmail extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            setPage: 'signIn',
            isValidEmail: false,
            errorMessage: '',
        }
        

        this.handleSignIn = this.handleSignIn.bind(this);
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();

        this.setFunction = this.setFunction.bind(this)
    
    }

    componentDidMount() {
        
        
    }

    setFunction(e,pageFunction ){
        e.preventDefault()
        this.setState({setPage: pageFunction})
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    handleChange = event => {
        if (!this.isValidEmail(event.target.value)) {
            this.setState({errorMessage:'valid', isValidEmail:false })
        } else {
            this.setState({errorMessage:'', isValidEmail:true})
        }

    };

    
    handleSignIn(e){
        e.preventDefault()
        const { dispatch } = this.props
        const username =  this.usernameRef.current
        const creds={username: username.value.trim()}
        dispatch(authOperations.loginUser(creds))
        
    }

    render(){
        var d = new Date();
        var n = d.getFullYear();
        const { isAuthenticated  } = this.props
        const {errorMessage, isValidEmail} = this.state
        var submitBtn =  (
            <Button variant="primary" size="lg" onClick={(e) => this.handleSignIn(e)} disabled>
                                    Submit
            </Button>
        )
        if (isValidEmail){
            submitBtn =  (
                <Button variant="primary" size="lg" onClick={(e) => this.handleSignIn(e)}>
                                        Submit
                </Button>
            )
        }
        
        return(
            
            <div className="d-flex align-items-center" style={{minHeight:'20vh'}}>
                {
                            <Form style={{width: '100%', minWidth:'330px', maxWidth:'450px', margin:'auto'}}>
                            <p>To continue to the Audio Planning Tool. Please provide an email address:</p>
                            {isAuthenticated ?  <Navigate to='/' replace={true}/>: null}
                            {isValidEmail ? <></>: <h4 style={fontColor}>Please provide an {errorMessage} email:</h4>}
                            <p> </p>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control onChange={this.handleChange} ref={this.usernameRef} type="email" placeholder="Enter email" />
                            </Form.Group>

                            <br />
                            <div className="d-grid gap-2">
                                { submitBtn }
                            </div>
                            <sub style={fontColor}>By providing your email you agree that you will opt in for future communications from CRA and your personal information will be handled in accordance with our privacy policy.</sub>     
                        </Form>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <UserEmail {...props} navigate={navigate} />
}

export default connect(mapStateToProps)(WithNavigate)