import React, { Component } from "react";


import Select, {ActionMeta, NonceProvider, StylesConfig } from 'react-select';
import { PieChart } from 'react-minimal-pie-chart';
import './style.css'

import chroma from 'chroma-js'
import {visualGraph} from './visualGraph';
import {visualGraph_v2} from './visualGraph_v2'
import * as htmlToImage from 'html-to-image';

import { connect } from 'react-redux'
import axios from 'axios'

import variables from '../redux/variables';
import Data from './Data'

const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#E81F76' : 'white',
      color: state.isSelected ? 'white' : 'inherit',

    }),
    control: (style) => ({
      // none of react-select's styles are passed to <Control />
      ...style,
      border:'none',

    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', border:'none', }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
  };

const selectItems = (rows, ref ,onChange) =>{
    return (<select ref={ref} onChange={()=>onChange()} className="form-select" aria-label="Default select example">
        {
            rows.map((row,k) =>
                <option value={`${k}`}>{row}</option>
            )
        }
    </select>)
}


const visualsGraph = (demo,mediaSelect, mediaValues, mediaPercentage) =>{

    var label1 = null
    var label2 = null
    var label3 = null
    var label4 = null

    var reach1 = null
    var reach2 = null
    var reach3 = null
    var reach4 = null

    let hide = "hidden"
    let text = ""
    let add_reach = 0

    let media1Style = { visibility: hide,height:"300px"}
    let media2Style = { visibility: hide,height:"350px"}
    let media3Style = { visibility: hide,height:"400px"}
    let media4Style = { visibility: hide}
    let showAdditionalReach = {visibility: hide}


    if (mediaSelect.length > 0){
        label1 = mediaSelect[0].label
        media1Style = { 
            visibility: 'visible',
            height:"300px",
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[0].color})`
        }
        reach1 = mediaSelect[0].reach
        
    }
    if (mediaSelect.length > 1){
        label2 = mediaSelect[1].label
        media2Style = { 
                visibility: 'visible',
                height:"350px",
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[1].color})`
        }
        text = "one more"
        add_reach = mediaValues[1] - mediaValues[0]
        showAdditionalReach = { visibility: 'visible'}
        reach2 = mediaSelect[1].reach
    }
    if (mediaSelect.length > 2){
        label3 = mediaSelect[2].label
        media3Style = { visibility: 'visible',
                        height:"400px",
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[2].color})`
                    }
        text = "two more"
        add_reach = mediaValues[2] - mediaValues[0]
        reach3 = mediaSelect[2].reach
    }
    if (mediaSelect.length > 3){
        label4 = mediaSelect[3].label
        media4Style = { visibility: 'visible',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[3].color})`
                      }
        text = "three more"
        add_reach = mediaValues[3] - mediaValues[0]
        reach4 = mediaSelect[3].reach
    }

    return(
        <div style={{ width:"100%",backgroundColor:"black", paddingTop:"10px", height: "600px" }}>
                    <h1 className="visual-header" style={{color:"white"}}>{demo}</h1>
                    <div className="reach-box text-center " style={showAdditionalReach}>
                            New people reached by adding <span style={{fontStyle:"italic"}}>{text}</span> channels: {Number(add_reach*1000).toLocaleString()}
                    </div>
                    <div className="imageDiv" style={media4Style}>
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label4}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[3]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[3]*1000).toLocaleString()}</p>
                                

                            </div>
                        </div>
                    </div>

                    {
                    <div style={media3Style} className="imageDiv bottomDiv"  >
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label3}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[2]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[2]*1000).toLocaleString()}</p>
                          
                            </div>
                        </div>
                    </div>
                    }

                    {
                    <div  style={media2Style} className="imageDiv bottomDivTwo">
                        <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                            <h5 className="card-title">{label2}</h5>
                            <div className="mt-auto ">
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[1]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[1]*1000).toLocaleString()}</p>
                                
                            </div>
                        </div>
                    </div>
                    }
                    {
                        <div  style={media1Style} className="imageDiv bottomDivThree">
                            <div className="card-body d-flex flex-column" style={{height:"100%"}} >
                                <h5 className="card-title">{label1}</h5>
                                <div className="mt-auto ">
                                    <div className="card-text">Channel Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[0]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Channel Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[0]*1000).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                        </div>
                    }  
                        
        </div>
    )
}

/*
const dataSurvey = {
    'Totals':['(000s)',21280,10523,2993,2387,5697,4826,7137],
    '1':['(000s)',13856,7152,1760,1385,3746,3406,4527],
    '2':['(000s)',5842,3686,1120,910,2173,1512,845],
    '3':['(000s)',5306,3138,829,659,1681,1457,1162],
    '4':['(000s)',3561,2229,546,433,1279,950,685],
    '1,2':['(000s)',15534,8132,2147,1701,4365,3767,4789],
    '1,3':['(000s)',15636,8166,2077,1637,4336,3830,4913],
    '1,4':['(000s)',14953,7803,1965,1548,4144,3659,4738],
    '2,3':['(000s)',8798,5384,1572,1269,3086,2298,1547],
    '2,4':['(000s)',7586,4761,1345,1092,2797,1964,1248],
    '3,4':['(000s)',6067,3583,873,687,1924,1659,1419],
    '1,2,3':['(000s)',16609,8733,2333,1850,4721,4011,5030],
    '1,2,4':['(000s)',16125,8486,2244,1779,4581,3905,4913],
    '1,3,4':['(000s)',15859,8287,2095,1647,4400,3887,4993],
    '2,3,4':['(000s)',9384,5732,1596,1283,3279,2453,1748],
    '1,2,3,4':['(000s)',16787,8835,2342,1854,4778,4058,5093],
}*/
const metroData = Data.metroData
    const metroPercentage = Data.metroPercentage

    const nationalData = Data.nationalData
    const nationalPercentage = Data.nationalPercentage

    const regionalData = Data.regionalData
      const regionalPercentage = Data.regionalPercentage
    
    

const orderOptions = (values) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };

class AudioReach extends Component{
    constructor(props){
        super(props);
        this.state = {
            media: [0,0,0,0],
            demo: 0,
            mediaValues: [0,0,0,0],
            mediaPercentage: [0,0,0,0],
            mediaReach:[0,0,0,0],
            mediaSelect: [ { value: 1, label: 'Commercial Radio', reach: 0, color:'#00aeef', isFixed:true }],
            marketSelect:[],
           
            demoSelect: [],
            
            marketData:metroData,
            marketPercentage:[],
            donut_chart_data: [],
            crafixed: true,
            selectcount: 0,
            media_select: [
                { value: 1, label: 'Commercial Radio', reach: 0, color:'#00aeef', isFixed:true },
                { value: 2, label: 'Digital Radio Streaming', reach: 0 , color:'#e81f76', isFixed:false},
                { value: 3, label: 'Free On-Demand Music Streaming', reach: 0 , color:'#07AD53', isFixed:false},
                { value: 4, label: 'Podcasts', reach: 0 , color:'#9164cc', isFixed:false},
            ],

        }

        this.refChart = React.createRef();
        this.refDemo = React.createRef();
        this.refMedia1 = React.createRef();
        this.refMedia2 = React.createRef();
        this.refMedia3 = React.createRef();
        this.refMedia4 = React.createRef();

        this.refMediaSelect = React.createRef();

        this.onChangeMedia = this.onChangeMedia.bind(this);
        this.onChangeDemo = this.onChangeDemo.bind(this);
        this.onChangeDemoSelect = this.onChangeDemoSelect.bind(this);
        this.onChangeMediaSelect = this.onChangeMediaSelect.bind(this);
        this.updateFiguresMulti = this.updateFiguresMulti.bind(this);
        this.handleDownloadImage = this.handleDownloadImage.bind(this)

        this.onChangeMarketSelect = this.onChangeMarketSelect.bind(this);
    }

    onChangeMediaSelect(value, actionMeta){
        //const refMediaSelect = this.refMediaSelect.current;
        /*option.sort(function(a, b) {
            return a.reach - b.reach;
          });*/
          var count = this.state.selectcount + 1
          this.setState({selectcount:count})
          if (count == 3){
            
            value = value.map(({ value,label, color, reach }) => ({ label: label, value: value,color: color, reach: reach, isFixed:false }));
            var media_select = this.state.media_select.map(({ value,label, color, reach }) => ({ label: label, value: value,color: color, reach: reach, isFixed:false }));
            this.setState({crafixed:false, media_select:media_select})
        }
          switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
              if (actionMeta.removedValue.isFixed) {
                return;
              }
              break;
            case 'clear':
              value = this.state.media_select.filter((v) => v.isFixed);
              break;
          }
      
        value = orderOptions(value);
        this.setState({mediaSelect: value },this.updateFiguresMulti)
        
    }

    mediaReturnKey(select_array){
        var values = []
        select_array.forEach(function (arrayItem) {
            values.push(arrayItem.value)
        })

        return values.sort().toString()
    }



    updateDonutData(mediaArray){
        this.setState({donut_chart_data:[]})
        const donut_data = mediaArray.map(({ label, reach, color }) => ({ label: label, value: reach,color: color }));
        
        this.setState({donut_chart_data:donut_data})

    }

    getDataPercentage(market){
        if (market.label === "Metro"){
            return metroPercentage
        }
        if (market.label === "Regional"){
            return regionalPercentage
        }
        if (market.label === "National"){
            return nationalPercentage
        }
    }

    updateFiguresMulti(){
        const dataSurvey = this.state.marketData
        //const dataPercentage = this.state.marketPercentage
        const mediaArray = this.state.mediaSelect
        this.updateDonutData(mediaArray)
        const dataPercentage = this.getDataPercentage(this.state.marketSelect)
        if (mediaArray.length > 0){
            const keyMedia1 = mediaArray[0].value.toString();
            const keyMedia2 = this.mediaReturnKey(mediaArray.slice(0, 2))
            const keyMedia3 = this.mediaReturnKey(mediaArray.slice(0, 3))
            const keyMedia4 = this.mediaReturnKey(mediaArray)
        
            //console.log(keyMedia1)
            //console.log(keyMedia2)
            //console.log(keyMedia3)
            //console.log(keyMedia4)
            var percentMedia1 = 0
            var percentMedia2 = 0
            var percentMedia3 = 0
            var percentMedia4 = 0

            //try {
                var demograph = parseInt(this.state.demo)
                this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],0,0,0]})
                console.log(demograph)
                console.log(dataSurvey[keyMedia1][demograph])
                //percentMedia1 =  dataSurvey[keyMedia1][demograph]/dataSurvey["Totals"][demograph]
                percentMedia1 =  dataPercentage[keyMedia1][demograph]
                //console.log(demograph)
                if (keyMedia2.length == 3){
                    console.log(dataSurvey[keyMedia2][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],0,0]})
                    //percentMedia2 =  dataSurvey[keyMedia2][demograph]/dataSurvey["Totals"][demograph]
                    percentMedia2 =  dataPercentage[keyMedia2][demograph]
                }
                if (keyMedia3.length == 5){
                    console.log(dataSurvey[keyMedia3][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],dataSurvey[keyMedia3][demograph],0]})
                    //percentMedia3 =  dataSurvey[keyMedia3][demograph]/dataSurvey["Totals"][demograph]
                    percentMedia3 =  dataPercentage[keyMedia3][demograph]
                }
                if (keyMedia4.length == 7){
                    console.log(dataSurvey[keyMedia4][this.state.demo])
                    this.setState({mediaValues:[dataSurvey[keyMedia1][demograph],dataSurvey[keyMedia2][demograph],dataSurvey[keyMedia3][demograph],dataSurvey[keyMedia4][demograph]]})
                    //percentMedia4 =  dataSurvey[keyMedia4][demograph]/dataSurvey["clearTotals"][demograph]
                    percentMedia4 =  dataPercentage[keyMedia4][demograph]
                }

                this.setState({mediaPercentage:[percentMedia1,percentMedia2,percentMedia3,percentMedia4]})
            //} catch(e) {}
        }else{
            this.setState({mediaValues:[0,0,0,0]})
        }
    }

    onChangeMedia(){
        const refMedia1 = this.refMedia1.current;
        const refMedia2 = this.refMedia2.current;
        const refMedia3 = this.refMedia3.current;
        const refMedia4 = this.refMedia4.current;

        this.setState({demo:this.refDemo.current.value,media:[refMedia1.value,refMedia2.value,refMedia3.value,refMedia4.value]},
            this.updateFigures)

        
    }


    updateFigures(){
        const dataSurvey = this.state.marketData
        const mediaArray = this.state.media
        const keyMedia1 = mediaArray[0].toString();
        const keyMedia2 = mediaArray.slice(0, 2).sort().toString();
        const keyMedia3 = mediaArray.slice(0, 3).sort().toString();
        const keyMedia4 = mediaArray.sort().toString();

        console.log(keyMedia1.toString())
        console.log(keyMedia2.toString())
        console.log(keyMedia3.toString())
        console.log(keyMedia4.toString())
        try {
            console.log(dataSurvey[keyMedia1][this.state.demo])
            this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],0,0,0]})
            if (!keyMedia2.includes('0')){
                console.log(dataSurvey[keyMedia2][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],0,0]})
            }
            if (!keyMedia3.includes('0')){
                console.log(dataSurvey[keyMedia3][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],dataSurvey[keyMedia3][this.state.demo],0]})
            }
            if (!keyMedia4.includes('0')){
                console.log(dataSurvey[keyMedia4][this.state.demo])
                this.setState({mediaValues:[dataSurvey[keyMedia1][this.state.demo],dataSurvey[keyMedia2][this.state.demo],dataSurvey[keyMedia3][this.state.demo],dataSurvey[keyMedia4][this.state.demo]]})
            }
        } catch(e) {}

    }

    onChangeDemo(){
        
        this.setState({demo:this.refDemo.current.value, },this.updateFiguresMulti())
        
        
    }

    onChangeMarketSelect(option){
        console.log(option)
        this.setState({
            marketSelect:option,marketData:option.value, mediaSelect:this.updateDataReach(this.state.mediaSelect,this.state.demoSelect.value,option.value)
        },this.updateFiguresMulti)
        
    }

    updateDataReach(mediaArray,index, marketData){
        const mediaReturn = mediaArray.map(({ value,label, color, isFixed }) => ({ label: label, value: value,color: color, reach: marketData[value][index], isFixed:isFixed }));

            //const refMediaSelect = this.refMediaSelect.current;
        /*mediaReturn.sort(function(a, b) {
            return a.reach - b.reach;
        });*/

        return mediaReturn
    }

    

    onChangeDemoSelect(option){
        const dataSurvey = this.state.marketData
        const media_select = [
            { value: 1, label: 'Commercial Radio', reach: dataSurvey['1'][option.value], color:'#00aeef', isFixed:this.state.crafixed },
            { value: 2, label: 'Digital Radio Streaming', reach: dataSurvey['2'][option.value] , color:'#e81f76', isFixed:false},
            { value: 3, label: 'Free On-Demand Music Streaming', reach: dataSurvey['3'][option.value] , color:'#07AD53', isFixed:false},
            { value: 4, label: 'Podcasts', reach: dataSurvey['4'][option.value] , color:'#9164cc', isFixed:false},
        ]
        console.log(option)
        this.setState({demo: option.value,
            demoSelect:option, media_select:media_select, mediaSelect:this.updateDataReach(this.state.mediaSelect,option.value, this.state.marketData)
        },this.updateFiguresMulti)
        
    }

    async handleDownloadImage(){
        
        const element = this.refChart.current;
        const dataUrl = await htmlToImage.toPng(element);

        const link = document.createElement('a');
        link.download = "Audio-Reach-Chart.png";
        link.href = dataUrl;
        link.click();

    }

    //07AD53


    componentDidMount(){


        
    }



    render(){
        const market = [
            { value: metroData, label: 'Metro' },
            { value: regionalData, label: 'Regional' },
            { value: nationalData, label: 'National' },

        ]
        const org_media = [
        "None",
        "Commercial Radio",
        "Online Radio Streaming",
        "Free On-Demand Music Streaming",
        "Podcasts",]


        const demo =[
            { value: 1, label: 'People 14+' },
            { value: 2, label: 'People 25-54' },
            { value: 3, label: 'People 16-24' },
            { value: 4, label: 'People 18-24' },
            { value: 5, label: 'People 25-39' },
            { value: 6, label: 'People 40-54' },
            { value: 7, label: 'People 55+' },
            { value: 8, label: 'Main GB' },
            { value: 9, label: 'Parents with Children in home' },
            { value: 10, label: 'Male' },
            { value: 11, label: 'Female' },
            { value: 12, label: 'HAVE MOTOR INSURANCE' },
            { value: 13, label: 'HEALTH INSURANCE: SWITCH OR NEW CUSTOMER N12M' },
            { value: 14, label: 'BANKING: Change MFI N6M' },
            { value: 15, label: 'NEW/USED CAR BUYERS N6M' },
            { value: 16, label: 'CHANGE MOBILE PHONE PROVIDER, CONSIDER N12M' },
            { value: 17, label: 'MAJOR HH APPLIANCE/FURNISHING BUY N12M' },
            { value: 18, label: 'OTHER HH APPLIANCE/FURNISHING BUY N12M' },
            { value: 19, label: 'DEPARTMENT STORE: PURCHASE FROM PMTH' },
            { value: 20, label: 'QSR AT LEAST MONTHLY (eat in, or takeaway, or home delivery)' },
            { value: 21, label: 'ALCOHOL PAST MONTH' },
            { value: 22, label: 'SUPERMARKET at least weekly' },
            { value: 23, label: 'BUILDING/STRUCTURE INSURANCE N12M (extremely likely/very likely)' },
            { value: 24, label: 'EXISTING BUILDING/STRUCTURE INSURANCE shop around for another policy n12m' },

        ]

        const defaultLabelStyle = {
            fontSize: '5px',
            fontFamily: 'sans-serif',
          };

          /*
            <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <PieChart style={{maxHeight: '500px'}}
                            label={({ dataEntry }) => dataEntry.label}
                            labelStyle={{
                                fontSize: '3px',
                                fontFamily: 'sans-serif',
                              }}
                            lineWidth={60} 
                            paddingAngle={5}
                            data={this.state.donut_chart_data}
                        />
            </div>

          */
        

        return(
            <>
                <div className="list-group">
                    <li style={{fontSize: '30px', fontWeight: '600' }}className="list-group-item" aria-current="true"><div className="float-end"> Powered By: <img  width="200px" src="/img/Nielsen_logo.png"/></div></li>
                    <li className="list-group-item" aria-current="true">The Audio planning tool is a quick reference application to gauge the cumulative reach in combining various audio formats;  broadcast audio, podcasts, and free on-demand music streaming, for key demographics and category purchase intenders. The Audio Planning tool is powered by Nielsen Consumer and Media View (CMV), a market leading single-source dataset, profiling Australian consumers’ demographics, psychographics, category, and media usage.
			        </li>
                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <div className="alert alert-steps text-center" role="alert">
                            STEP 1
                        </div>
                        <div className="d-flex gap-2 w-100 justify-content-between">
                            <div style={{textAlign: "left", width: "100%"}}>
                                <h3 className="select-box-label">Select Market</h3>
                                <Select
                                    options={market}
                                    value={this.state.marketSelect}
                                    onChange={(option)=>this.onChangeMarketSelect(option)}
                                    className="select-control"
                                    styles={customStyles}
                                />
                            </div>
                        
                        </div>
                    </div>
                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <div className="alert alert-steps text-center" role="alert">
                            STEP 2
                        </div>
                        <div className="d-flex gap-2 w-100 justify-content-between">
                            <div style={{textAlign: "left", width: "100%"}}>
                                <h3 className="select-box-label">Select audience</h3>
                                <Select
                                    options={demo}
                                    value={this.state.demoSelect}
                                    onChange={(option)=>this.onChangeDemoSelect(option)}
                                    className="select-control"
                                    styles={customStyles}
                                />
                            </div>
                        
                        </div>
                    </div>
                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <div className="alert alert-steps text-center" role="alert">
                            STEP 3
                        </div>
                        <div className="d-flex gap-2 w-100 justify-content-between">
                        <div style={{textAlign: "left", width: "100%"}}>
                        <h3 className="select-box-label">Select Audio channel (up to 4)</h3>
                            <Select
                                    isMulti
                                    name="media_select"
                                    options={this.state.media_select}
                                    onChange={(option, actionMeta)=>this.onChangeMediaSelect(option,actionMeta)}
                                    value={this.state.mediaSelect}
                                    isClearable={this.state.mediaSelect.some((v) => !v.isFixed)}
                                    className="select-control"
                                    styles={colourStyles}
                                />
                        </div>
                
                        </div>
                    </div>

                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                    {visualGraph(this.state.demoSelect.label,
                                this.state.mediaSelect,
                                this.state.mediaValues, 
                                this.state.mediaPercentage,this.refChart)}
                    </div>

                    <button type="button" onClick={this.handleDownloadImage} className="btn btn-lg btn-block" style={{backgroundColor:"#0A2240", color:'white'}}>Download as Image</button>


                    

                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                        <p style={{fontSize:'8px'}}>

                            CMV BUILD DETAILS:
                            <ul>
                            <li>Radio: Media Summary- Any Commercial Radio P7D</li>
                            <li>Commercial on-demand music streaming: Online Music Streaming Services P7D (Includes Google Play Music; or iTunes; or SoundCloud; or Tidal; or Spotify; or Vevo; or Apple Music; or Amazon Music; or YouTube Music; or LiSTNR; or iHeart Radio; or Sonos Radio; or Other) with an AND [Subscribe to Free Music OR Neither Subscribe or Pay for Music]</li>
                            <li>Digital radio streaming: Live online radio services listened P7D (iHeart Radio; or RadioApp; or TuneIn; or Apple Music; or iTunes; or LiSTNR; or Nova Player; or Smooth Player, or Other)</li>
                            <li>Commercial Podcast: Used the following services to listen to a Podcast, P7D (Google Podcast; or Apple Podcast; or iTunes; or Spotify; or LiSTNR; or Acast; or SoundCloud; or iHeart Radio; or Nova Player; or Smooth Player; or Other), OR Listen to catch-up radio Podcast (Hit; or MMM; or Nova; or Smooth; or KIIS; or Gold; or Nine Radio; or Other) with an AND [Subscribe to Free Music OR Neither Subscribe or Pay for Music]</li>
                            </ul>

                        </p>
                    </div>
  
                </div>
               
                

                
            </>
        )
    }
}


function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}



export default connect(mapStateToProps)(AudioReach)
