const metroData = { 
    'Totals':['000s',15670,8036,2156,1716,4359,3677,5018,8337,5931,7696,7974,11540,2186,2335,905,3350,6507,8087,12809,12363,8636,13757,854,2416],
    '1':['000s',10219,5270,1121,863,2642,2628,3522,5591,4207,5194,5025,7796,1457,1562,617,2213,4360,5351,8426,8043,5801,9056,567,1707],
    '2':['000s',4501,2695,700,552,1510,1185,937,2360,1847,2445,2056,3249,784,796,310,1538,1995,2539,3741,3751,2438,3965,319,614],
    '3':['000s',4463,2565,597,431,1340,1225,1140,2318,1800,2282,2181,3241,643,689,280,1106,1934,2418,3693,3598,2393,3934,229,675],
    '4':['000s',2791,1717,377,285,938,779,602,1451,1170,1484,1307,1985,436,478,175,801,1216,1538,2290,2294,1499,2475,164,383],
    '1,2':['000s',11653,6119,1414,1102,3182,2938,3760,6288,4692,5913,5741,8765,1684,1808,713,2709,4947,6113,9591,9249,6521,10300,649,1872],
    '1,3':['000s',11750,6130,1409,1079,3165,2964,3854,6336,4737,5916,5833,8838,1666,1778,712,2598,5008,6166,9676,9298,6552,10385,641,1897],
    '1,4':['000s',11146,5832,1288,994,2998,2835,3689,6036,4541,5644,5503,8421,1596,1715,678,2483,4749,5845,9159,8815,6252,9861,616,1814],
    '2,3':['000s',7021,4100,1087,834,2283,1817,1578,3637,2805,3685,3335,5042,1129,1173,457,2027,3076,3867,5814,5759,3754,6175,437,984],
    '2,4':['000s',5883,3531,905,708,1993,1538,1229,3060,2411,3152,2731,4199,990,1029,386,1834,2596,3285,4871,4864,3155,5191,395,802],
    '3,4':['000s',4998,2848,647,472,1461,1387,1330,2623,2024,2546,2452,3626,718,764,312,1193,2148,2682,4117,4006,2692,4407,256,759],
    '1,2,3':['000s',12571,6607,1612,1252,3489,3118,3960,6722,4978,6337,6235,9366,1808,1936,761,2885,5339,6597,10347,10001,6965,11084,695,1982],
    '1,2,4':['000s',12146,6397,1512,1180,3364,3033,3854,6518,4854,6150,5996,9079,1762,1890,737,2815,5158,6378,9980,9649,6757,10722,678,1929],
    '1,3,4':['000s',11921,6221,1429,1097,3208,3013,3906,6431,4802,6000,5921,8956,1694,1805,721,2622,5074,6255,9806,9430,6643,10530,650,1920],
    '2,3,4':['000s',7457,4332,1128,868,2382,1950,1727,3884,2996,3903,3553,5346,1189,1233,483,2096,3263,4087,6161,6087,3994,6558,461,1053],
    '1,2,3,4':['000s',12715,6680,1631,1269,3524,3156,4003,6798,5036,6407,6308,9460,1831,1955,767,2905,5398,6673,10455,10109,7040,11206,703,2002],
    }
    const metroPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',65.21,65.58,51.99,50.29,60.61,71.47,70.19,67.06,70.93,67.49,63.02,67.56,66.65,66.9,68.18,66.06,67,66.17,65.78,65.06,67.17,65.83,66.39,70.65],
    '2':['Vert%',28.72,33.54,32.47,32.17,34.64,32.23,18.67,28.31,31.14,31.77,25.78,28.15,35.86,34.09,34.25,45.91,30.66,31.4,29.21,30.34,28.23,28.82,37.35,25.41],
    '3':['Vert%',28.48,31.92,27.69,25.12,30.74,33.32,22.72,27.8,30.35,29.65,27.35,28.08,29.41,29.51,30.94,33.01,29.72,29.9,28.83,29.1,27.71,28.6,26.81,27.94],
    '4':['Vert%',17.81,21.37,17.49,16.61,21.52,21.19,12,17.4,19.73,19.28,16.39,17.2,19.95,20.47,19.34,23.91,18.69,19.02,17.88,18.56,17.36,17.99,19.2,15.85],
    '1,2':['Vert%',74.37,76.14,65.58,64.22,73,79.9,74.93,75.42,79.11,76.83,72,75.95,77.04,77.43,78.78,80.87,76.03,75.59,74.88,74.81,75.51,74.87,76,77.48],
    '1,3':['Vert%',74.98,76.28,65.35,62.88,72.61,80.61,76.8,76,79.87,76.87,73.15,76.59,76.21,76.15,78.67,77.55,76.96,76.25,75.54,75.21,75.87,75.49,75.06,78.52],
    '1,4':['Vert%',71.13,72.57,59.74,57.93,68.78,77.1,73.52,72.4,76.56,73.34,69.01,72.97,73.01,73.45,74.92,74.12,72.98,72.28,71.5,71.3,72.39,71.68,72.13,75.08],
    '2,3':['Vert%',44.81,51.02,50.42,48.6,52.37,49.42,31.45,43.62,47.29,47.88,41.82,43.69,51.65,50.24,50.5,60.51,47.27,47.82,45.39,46.58,43.47,44.89,51.17,40.73],
    '2,4':['Vert%',37.54,43.94,41.98,41.26,45.72,41.83,24.49,36.7,40.65,40.96,34.25,36.39,45.29,44.07,42.65,54.75,39.9,40.62,38.03,39.34,36.53,37.73,46.25,33.2],
    '3,4':['Vert%',31.9,35.44,30.01,27.51,33.52,37.72,26.5,31.46,34.13,33.08,30.75,31.42,32.85,32.72,34.48,35.61,33.01,33.16,32.14,32.4,31.17,32.03,29.98,31.42],
    '1,2,3':['Vert%',80.22,82.22,74.77,72.96,80.04,84.8,78.92,80.63,83.93,82.34,78.19,81.16,82.71,82.91,84.09,86.12,82.05,81.58,80.78,80.89,80.65,80.57,81.38,82.04],
    '1,2,4':['Vert%',77.51,79.6,70.13,68.76,77.17,82.49,76.8,78.18,81.84,79.91,75.19,78.67,80.6,80.94,81.44,84.03,79.27,78.87,77.91,78.05,78.24,77.94,79.39,79.84],
    '1,3,4':['Vert%',76.08,77.41,66.28,63.93,73.59,81.94,77.84,77.14,80.96,77.96,74.25,77.61,77.49,77.3,79.67,78.27,77.98,77.35,76.56,76.28,76.92,76.54,76.11,79.47],
    '2,3,4':['Vert%',47.59,53.91,52.32,50.58,54.65,53.03,34.42,46.59,50.51,50.71,44.56,46.33,54.39,52.81,53.37,62.57,50.15,50.54,48.1,49.24,46.25,47.67,53.98,43.58],
    '1,2,3,4':['Vert%',81.14,83.13,75.65,73.95,80.84,85.83,79.77,81.54,84.91,83.25,79.11,81.98,83.76,83.73,84.75,86.72,82.96,82.52,81.62,81.77,81.52,81.46,82.32,82.86],
    }

    const nationalData = { 
    'Totals':['000s',22119,10939,2958,2338,5838,5101,7568,11826,8166,10886,11233,16570,2914,3152,1209,4280,9085,11261,18049,17141,12041,19340,1157,3561],
    '1':['000s',14135,7105,1555,1184,3519,3586,5049,7725,5678,7146,6989,10913,1932,2064,794,2822,5962,7315,11671,10998,7882,12482,764,2427],
    '2':['000s',5937,3485,907,715,1909,1575,1332,3096,2408,3233,2705,4377,990,1016,382,1831,2619,3296,4895,4846,3221,5216,407,871],
    '3':['000s',5995,3372,794,571,1746,1627,1623,3121,2372,3112,2884,4423,831,903,370,1339,2579,3200,4968,4733,3224,5287,332,934],
    '4':['000s',3621,2216,475,358,1205,1011,813,1893,1520,1933,1688,2633,553,599,232,938,1581,1974,2986,2915,1948,3210,218,538],
    '1,2':['000s',16079,8223,1943,1505,4199,4025,5416,8676,6330,8133,7946,12265,2230,2392,915,3411,6769,8348,13241,12594,8879,14168,868,2670],
    '1,3':['000s',16249,8253,1935,1464,4196,4056,5568,8764,6380,8171,8079,12388,2208,2364,927,3293,6851,8421,13391,12674,8945,14311,880,2700],
    '1,4':['000s',15374,7847,1768,1350,3977,3870,5294,8326,6118,7766,7608,11778,2112,2266,885,3143,6485,7963,12653,11998,8496,13558,833,2581],
    '2,3':['000s',9441,5404,1432,1093,2948,2456,2272,4894,3741,4994,4447,6912,1444,1523,592,2460,4104,5113,7775,7579,5062,8286,587,1414],
    '2,4':['000s',7791,4602,1165,908,2551,2051,1746,4055,3179,4178,3613,5696,1258,1307,493,2195,3425,4282,6419,6314,4197,6857,510,1160],
    '3,4':['000s',6695,3739,858,622,1917,1822,1877,3507,2657,3478,3217,4940,923,996,407,1435,2866,3547,5523,5258,3615,5899,366,1067],
    '1,2,3':['000s',17378,8895,2204,1697,4610,4286,5740,9298,6733,8746,8632,13141,2392,2570,992,3636,7311,9016,14294,13616,9523,15274,943,2837],
    '1,2,4':['000s',16759,8607,2069,1602,4445,4162,5561,9006,6560,8465,8294,12724,2329,2500,961,3540,7059,8705,13779,13130,9213,14752,908,2762],
    '1,3,4':['000s',16487,8380,1962,1489,4261,4120,5644,8896,6468,8293,8195,12558,2244,2398,940,3320,6949,8542,13574,12858,9072,14510,892,2744],
    '2,3,4':['000s',9996,5708,1482,1134,3092,2616,2460,5204,3981,5280,4716,7315,1522,1597,622,2534,4347,5393,8222,7997,5370,8772,618,1521],
    '1,2,3,4':['000s',17575,9002,2227,1718,4664,4338,5799,9407,6811,8848,8727,13280,2423,2598,1002,3658,7398,9119,14446,13765,9628,15440,956,2874],
    }
    const nationalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',63.9,64.95,52.57,50.64,60.28,70.3,66.72,65.32,69.53,65.64,62.22,65.86,66.3,65.48,65.67,65.93,65.62,64.96,64.66,64.16,65.46,64.54,66.03,68.16],
    '2':['Vert%',26.84,31.86,30.66,30.58,32.7,30.88,17.6,26.18,29.49,29.7,24.08,26.42,33.97,32.23,31.6,42.78,28.83,29.27,27.12,28.27,26.75,26.97,35.18,24.46],
    '3':['Vert%',27.1,30.83,26.84,24.42,29.91,31.9,21.45,26.39,29.05,28.59,25.67,26.69,28.52,28.65,30.6,31.29,28.39,28.42,27.53,27.61,26.78,27.34,28.69,26.23],
    '4':['Vert%',16.37,20.26,16.06,15.31,20.64,19.82,10.74,16.01,18.61,17.76,15.03,15.89,18.98,19,19.19,21.92,17.4,17.53,16.54,17.01,16.18,16.6,18.84,15.11],
    '1,2':['Vert%',72.69,75.17,65.69,64.37,71.93,78.91,71.56,73.36,77.52,74.71,70.74,74.02,76.53,75.89,75.68,79.7,74.51,74.13,73.36,73.47,73.74,73.26,75.02,74.98],
    '1,3':['Vert%',73.46,75.45,65.42,62.62,71.87,79.51,73.57,74.11,78.13,75.06,71.92,74.76,75.77,75,76.67,76.94,75.41,74.78,74.19,73.94,74.29,74,76.06,75.82],
    '1,4':['Vert%',69.51,71.73,59.77,57.74,68.12,75.87,69.95,70.4,74.92,71.34,67.73,71.08,72.48,71.89,73.2,73.43,71.38,70.71,70.1,70,70.56,70.1,72,72.48],
    '2,3':['Vert%',42.68,49.4,48.41,46.75,50.5,48.15,30.02,41.38,45.81,45.88,39.59,41.71,49.55,48.32,48.97,57.48,45.17,45.4,43.08,44.22,42.04,42.84,50.73,39.71],
    '2,4':['Vert%',35.22,42.07,39.38,38.84,43.7,40.21,23.07,34.29,38.93,38.38,32.16,34.38,43.17,41.47,40.78,51.29,37.7,38.03,35.56,36.84,34.86,35.46,44.08,32.58],
    '3,4':['Vert%',30.27,34.18,29.01,26.6,32.84,35.72,24.8,29.65,32.54,31.95,28.64,29.81,31.67,31.6,33.66,33.53,31.55,31.5,30.6,30.67,30.02,30.5,31.63,29.96],
    '1,2,3':['Vert%',78.57,81.31,74.51,72.58,78.97,84.02,75.85,78.62,82.45,80.34,76.85,79.31,82.09,81.54,82.05,84.95,80.47,80.06,79.2,79.44,79.09,78.98,81.5,79.67],
    '1,2,4':['Vert%',75.77,78.68,69.95,68.52,76.14,81.59,73.48,76.15,80.33,77.76,73.84,76.79,79.92,79.31,79.49,82.71,77.7,77.3,76.34,76.6,76.51,76.28,78.48,77.56],
    '1,3,4':['Vert%',74.54,76.61,66.33,63.69,72.99,80.77,74.58,75.22,79.21,76.18,72.95,75.79,77.01,76.08,77.75,77.57,76.49,75.85,75.21,75.01,75.34,75.03,77.1,77.06],
    '2,3,4':['Vert%',45.19,52.18,50.1,48.5,52.96,51.28,32.51,44,48.75,48.5,41.98,44.15,52.23,50.67,51.45,59.21,47.85,47.89,45.55,46.65,44.6,45.36,53.41,42.71],
    '1,2,3,4':['Vert%',79.46,82.29,75.29,73.48,79.89,85.04,76.63,79.55,83.41,81.28,77.69,80.14,83.15,82.42,82.88,85.47,81.43,80.98,80.04,80.3,79.96,79.83,82.63,80.71],
    }

    const regionalData = { 
    'Totals':['000s',6449,2903,802,622,1479,1424,2550,3489,2235,3190,3259,5030,728,817,304,930,2578,3174,5240,4778,3404,5583,302,1146],
    '1':['000s',3916,1835,434,321,877,958,1527,2134,1471,1952,1964,3117,475,502,177,609,1603,1964,3245,2954,2081,3427,197,721],
    '2':['000s',1437,790,207,163,399,391,395,736,561,788,649,1128,206,221,72,293,624,757,1154,1094,782,1251,88,257],
    '3':['000s',1533,807,196,140,405,402,482,803,572,830,703,1182,187,214,90,233,645,782,1275,1135,831,1353,102,258],
    '4':['000s',830,499,98,73,266,233,212,442,350,449,381,648,117,121,57,137,365,436,696,622,449,735,54,155],
    '1,2':['000s',4425,2104,529,403,1017,1087,1656,2388,1638,2220,2205,3500,546,585,202,703,1822,2236,3650,3345,2358,3868,218,799],
    '1,3':['000s',4500,2123,526,385,1031,1092,1715,2428,1643,2254,2245,3550,542,585,215,696,1843,2254,3715,3376,2393,3926,239,803],
    '1,4':['000s',4228,2015,481,356,979,1036,1605,2290,1577,2122,2106,3357,516,551,208,660,1736,2118,3494,3183,2244,3697,217,767],
    '2,3':['000s',2420,1304,345,259,665,638,695,1256,935,1308,1112,1870,315,349,135,433,1028,1245,1961,1821,1308,2111,149,430],
    '2,4':['000s',1908,1071,260,200,558,513,517,995,768,1026,882,1498,268,277,106,361,829,997,1548,1449,1042,1666,115,358],
    '3,4':['000s',1697,891,211,151,456,436,547,884,632,932,765,1314,206,233,95,242,718,865,1406,1252,923,1492,110,308],
    '1,2,3':['000s',4807,2288,592,445,1120,1168,1781,2577,1755,2410,2397,3776,584,634,231,752,1972,2418,3948,3616,2558,4190,249,855],
    '1,2,4':['000s',4614,2210,557,422,1081,1129,1707,2488,1706,2316,2298,3645,567,610,224,725,1901,2327,3799,3481,2456,4030,231,834],
    '1,3,4':['000s',4566,2159,533,392,1053,1107,1738,2465,1666,2293,2273,3602,550,594,219,697,1875,2287,3767,3427,2429,3979,242,824],
    '2,3,4':['000s',2540,1376,354,266,710,665,733,1320,985,1377,1163,1970,333,364,139,438,1085,1306,2061,1910,1376,2214,157,468],
    '1,2,3,4':['000s',4860,2321,597,450,1140,1182,1796,2610,1776,2442,2419,3820,592,642,234,753,2000,2446,3992,3656,2588,4234,252,873],
    }
    const regionalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',60.72,63.21,54.11,51.61,59.3,67.28,59.88,61.16,65.82,61.19,60.26,61.97,65.25,61.44,58.22,65.48,62.18,61.88,61.93,61.83,61.13,61.38,65.23,62.91],
    '2':['Vert%',22.28,27.21,25.81,26.21,26.98,27.46,15.49,21.09,25.1,24.7,19.91,22.43,28.3,27.05,23.68,31.51,24.2,23.85,22.02,22.9,22.97,22.41,29.14,22.43],
    '3':['Vert%',23.77,27.8,24.44,22.51,27.38,28.23,18.9,23.02,25.59,26.02,21.57,23.5,25.69,26.19,29.61,25.05,25.02,24.64,24.33,23.75,24.41,24.23,33.77,22.51],
    '4':['Vert%',12.87,17.19,12.22,11.74,17.99,16.36,8.31,12.67,15.66,14.08,11.69,12.88,16.07,14.81,18.75,14.73,14.16,13.74,13.28,13.02,13.19,13.16,17.88,13.53],
    '1,2':['Vert%',68.62,72.48,65.96,64.79,68.76,76.33,64.94,68.44,73.29,69.59,67.66,69.58,75,71.6,66.45,75.59,70.67,70.45,69.66,70.01,69.27,69.28,72.19,69.72],
    '1,3':['Vert%',69.78,73.13,65.59,61.9,69.71,76.69,67.25,69.59,73.51,70.66,68.89,70.58,74.45,71.6,70.72,74.84,71.49,71.01,70.9,70.66,70.3,70.32,79.14,70.07],
    '1,4':['Vert%',65.56,69.41,59.98,57.23,66.19,72.75,62.94,65.63,70.56,66.52,64.62,66.74,70.88,67.44,68.42,70.97,67.34,66.73,66.68,66.62,65.92,66.22,71.85,66.93],
    '2,3':['Vert%',37.53,44.92,43.02,41.64,44.96,44.8,27.25,36,41.83,41,34.12,37.18,43.27,42.72,44.41,46.56,39.88,39.22,37.42,38.11,38.43,37.81,49.34,37.52],
    '2,4':['Vert%',29.59,36.89,32.42,32.15,37.73,36.03,20.27,28.52,34.36,32.16,27.06,29.78,36.81,33.9,34.87,38.82,32.16,31.41,29.54,30.33,30.61,29.84,38.08,31.24],
    '3,4':['Vert%',26.31,30.69,26.31,24.28,30.83,30.62,21.45,25.34,28.28,29.22,23.47,26.12,28.3,28.52,31.25,26.02,27.85,27.25,26.83,26.2,27.12,26.72,36.42,26.88],
    '1,2,3':['Vert%',74.54,78.82,73.82,71.54,75.73,82.02,69.84,73.86,78.52,75.55,73.55,75.07,80.22,77.6,75.99,80.86,76.49,76.18,75.34,75.68,75.15,75.05,82.45,74.61],
    '1,2,4':['Vert%',71.55,76.13,69.45,67.85,73.09,79.28,66.94,71.31,76.33,72.6,70.51,72.47,77.88,74.66,73.68,77.96,73.74,73.31,72.5,72.85,72.15,72.18,76.49,72.77],
    '1,3,4':['Vert%',70.8,74.37,66.46,63.02,71.2,77.74,68.16,70.65,74.54,71.88,69.75,71.61,75.55,72.71,72.04,74.95,72.73,72.05,71.89,71.72,71.36,71.27,80.13,71.9],
    '2,3,4':['Vert%',39.39,47.4,44.14,42.77,48.01,46.7,28.75,37.83,44.07,43.17,35.69,39.17,45.74,44.55,45.72,47.1,42.09,41.15,39.33,39.97,40.42,39.66,51.99,40.84],
    '1,2,3,4':['Vert%',75.36,79.95,74.44,72.35,77.08,83.01,70.43,74.81,79.46,76.55,74.23,75.94,81.32,78.58,76.97,80.97,77.58,77.06,76.18,76.52,76.03,75.84,83.44,76.18],
    }


const graph_source = 'Nielsen CMV, National Survey 8 2024'

const exportedObject = {
    metroData,
    metroPercentage,
    nationalData,
    nationalPercentage,
    regionalData,
    regionalPercentage,
    graph_source

}

export default exportedObject


    