import { PieChart } from 'react-minimal-pie-chart';


const progressBar = (demo,label1,label2,label3,label4,mediaPercentage,media1progress,media2progress,media3progress,media4progress) =>{
    <div class="col-md-4 bg-light">
        <div class="h-100 p-5 text-bg-dark">
        <h2>Reach: {demo}</h2>
        <ul class="list-group">
            <li class="list-group-item">

                <h5 className="card-title">{label1}</h5>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-label="Success example" style={media1progress} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> Channel Reach {Number(mediaPercentage[0]*100).toFixed(1)}%</div>
                </div>

                
            </li>
            <li class="list-group-item">
                <h5 className="card-title">{label2}</h5>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-label="Success example" style={media2progress} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> Combined Reach {Number(mediaPercentage[1]*100).toFixed(1)}%</div>
                </div>
            </li>
            <li class="list-group-item">
                <h5 className="card-title">{label3}</h5>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-label="Success example" style={media3progress} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> Combined Reach {Number(mediaPercentage[2]*100).toFixed(1)}%</div>
                </div>
            </li>

            <li class="list-group-item">
                <h5 className="card-title">{label4}</h5>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-label="Success example" style={media4progress} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> Combined Reach {Number(mediaPercentage[3]*100).toFixed(1)}%</div>
                </div>
            </li>
        </ul>
        </div>
    </div>
}

export const visualGraph_v3 = (demo,mediaSelect, mediaValues, mediaPercentage, refChart) =>{

    var label1 = null
    var label2 = null
    var label3 = null
    var label4 = null

    var reach1 = null
    var reach2 = null
    var reach3 = null
    var reach4 = null

    let hide = "hidden"
    let text = ""
    let add_reach = 0

    let media1Style = { visibility: hide,height:"300px"}
    let media2Style = { visibility: hide,height:"350px"}
    let media3Style = { visibility: hide,height:"400px"}
    let media4Style = { visibility: hide}
    let showAdditionalReach = {visibility: hide}

    let media1progress = { visibility: hide,height:"300px"}
    let media2progress = { visibility: hide,height:"350px"}
    let media3progress = { visibility: hide,height:"400px"}
    let media4progress = { visibility: hide}


    if (mediaSelect.length > 0){
        label1 = mediaSelect[0].label
        media1Style = { 
            visibility: 'visible',
            height:'400px',
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[0].color})`,
            borderTop: `${mediaSelect[0].color}`,
            bottom: 0,
            zIndex: 4,
        }
        media1progress = { backgroundColor: `${mediaSelect[0].color}`, width: `${Number(mediaPercentage[0]*100).toFixed(1)}%`}
        reach1 = mediaSelect[0].reach
        
    }
    if (mediaSelect.length > 1){
        label2 = mediaSelect[1].label
        media2Style = { 
                visibility: 'visible',
                height:"400px",
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[1].color})`,
                borderTop: `${mediaSelect[1].color}`,
                bottom: 0,
                zIndex: 3,
        }
        text = "one more"
        add_reach = mediaValues[1] - mediaValues[0]
        showAdditionalReach = { visibility: 'visible'}
        reach2 = mediaSelect[1].reach
        media2progress = { backgroundColor: `${mediaSelect[1].color}`,width: `${Number(mediaPercentage[1]*100).toFixed(1)}%`}
    }
    if (mediaSelect.length > 2){
        label3 = mediaSelect[2].label
        media3Style = { visibility: 'visible',
                        height:'400px',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[2].color})`,
                        borderTop: `${mediaSelect[2].color}`,
                        bottom: 0,
                        zIndex: 2,
                    }
        text = "two more"
        add_reach = mediaValues[2] - mediaValues[0]
        reach3 = mediaSelect[2].reach
        media3progress = { backgroundColor: `${mediaSelect[2].color}`,width: `${Number(mediaPercentage[2]*100).toFixed(1)}%`}
    }
    if (mediaSelect.length > 3){
        label4 = mediaSelect[3].label
        media4Style = { visibility: 'visible',
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), ${mediaSelect[3].color})`,
                        borderTop: `${mediaSelect[3].color}`,
                        height:'400px',
                        bottom: 0,
                        zIndex: 1,
                      }
        text = "three more"
        add_reach = mediaValues[3] - mediaValues[0]
        reach4 = mediaSelect[3].reach
        media4progress = { backgroundColor: `${mediaSelect[3].color}`,width: `${Number(mediaPercentage[3]*100).toFixed(1)}%`}
    }

    return(
        <>
        <div class="row align-items-md-stretch">
   
                    <div class="col-md-12 bg-light">
                    <div className="new" ref={refChart} style={{ maxWidth:"1024",backgroundColor:"white", paddingTop:"10px", position:"relative" }}>
                    <h1 className="visual-header" style={{color:"black"}}>Reach: {demo}</h1>
                    <div className="row" style={{height:'425px', marginLeft: 'auto'}}>
                        <div className="col">
                            <div className="card boxDiv" style={media1Style}>
                                <div className="card-body d-flex flex-column"  >
                                    <div className="card-header-title">
                                    <h3 className="card-title">{label1}</h3>
                                    </div>
                                    <div className="mt-auto ">
                                        <h1>+{((Number(mediaPercentage[0]))*100).toFixed(1)}%</h1>
                                        <div className="card-text">Channel Reach %</div>
                                        <p className="card-text textPercentage">{Number(mediaPercentage[0]*100).toFixed(1)}%</p>
                                        <div className="card-text borderDot">Channel Reach</div>
                                        <p className="card-text textNumber">{Number(mediaValues[0]*1000).toLocaleString()}</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card boxDiv" style={media2Style}>
                            <div className="card-body d-flex flex-column"  >
                            <div className="card-header-title">
                                <h3 className="card-title">{label2}</h3>
                               </div>
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[1])-Number(mediaPercentage[0]))*100).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[1]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[1]*1000).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card boxDiv" style={media3Style}>
                            <div className="card-body d-flex flex-column"  >
                            <div className="card-header-title">
                                <h3 className="card-title">{label3}</h3>
                                </div>
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[2])-Number(mediaPercentage[1]))*100).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[2]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[2]*1000).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card boxDiv" style={media4Style}>
                            <div className="card-body d-flex flex-column"  >
                            <div className="card-header-title">
                                <h3 className="card-title">{label4}</h3>
                               </div>
                                <div className="mt-auto ">
                                    <h1>+{((Number(mediaPercentage[3])-Number(mediaPercentage[2]))*100).toFixed(1)}%</h1>
                                    <div className="card-text">Combined Reach %</div>
                                    <p className="card-text textPercentage">{Number(mediaPercentage[3]*100).toFixed(1)}%</p>
                                    <div className="card-text borderDot">Combined Reach</div>
                                    <p className="card-text textNumber">{Number(mediaValues[3]*1000).toLocaleString()}</p>
                                 
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row reach-box text-center " style={showAdditionalReach}>
                            New people reached by adding <span style={{fontStyle:"italic"}}>{text}</span> channels: {Number(add_reach*1000).toLocaleString()}
                    </div>
                    
                        
                    </div>
                    </div>
                </div>
        
        
        </>
    )
}